<template>
	<footer id="footer" v-if="$route.name != 'homePage'">
		<p>
			<!-- <router-link class="f-w-500 link-info mx-3" to="/privacy-policy">Privacy Policy</router-link> -->
			<a class="f-w-500 link-info mx-3" target="_blank" download href="public/docs/2be_Privacy_Policy.pdf">Privacy Policy</a>
			<span>|</span>
			<!-- <router-link class="f-w-500 link-info mx-3" to="/terms-conditions">Terms & Conditions</router-link> -->
			<a class="f-w-500 link-info mx-3" target="_blank" download href="public/docs/Terms_and_conditions.pdf">Terms & Conditions</a>
		</p>
	</footer>
</template>

<script type="text/javascript">
export default {
}
</script>
