<template lang="html">
  <header id="header" v-if="$route.name != 'homePage'">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <div class="container">
        <b-navbar-brand to="/">
          <img src="public/images/2be.svg">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="mr-lg-4" to="/"><i class="fas fa-home-lg-alt"></i> Home</b-nav-item>
            <b-nav-item to="/contact"><i class="fas fa-envelope"></i> Contact</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </header>
</template>

<script>
export default {
}
</script>
